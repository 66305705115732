import React, { useContext } from 'react';
import { BodyWrapper } from './Profile.element';
import CardPage from '../../component/Card/CardPage';
import CardArea from '../../component/Tabbar/CardArea';
import { DataContext } from '../../context/DataContext';

function Profile() {
  const {
    currentUser,
    token,
    selectedCardData,
    selectedExternalCardData,
    externalStars,
    toggleExternalStar
  } = useContext(DataContext);

  return (
    <BodyWrapper>
      <CardPage data={selectedCardData} currentUser={currentUser} token={token} />
      <CardArea activeTabContent={selectedExternalCardData} externalStars={externalStars} toggleExternalStar={toggleExternalStar} />
    </BodyWrapper>
  );
}

export default Profile;
