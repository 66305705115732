import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Styled Components
const QuestionContainer = styled.div`
  background-color: #f9b06b;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const QuestionText = styled.div`
  font-size: 20px;
  color: var(--text-color);
`;

const AnswerItem = styled.div`
  background-color: #fff;
  border: 1px solid  #dee2e6;
  margin: 10px 0;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const AnswerTextArea = styled.textarea`
  width: 100%;
  max-width: 500px;
  height: 150px;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 16px;
  resize: vertical;
`;

function QuestionAntwortComponent({ file_name, user, questionData }) {
  const [showAnswers, setShowAnswers] = useState({});
  const [showInput, setShowInput] = useState({});
  const [newAnswer, setNewAnswer] = useState('');
  const [ansewrs, setAnswers] = useState([]);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (questionData && questionData.length > 0) {
        const frageIdsString = questionData.map((frage) => frage.FrageId).join(',');
        const response = await axios.get(`https://api.wiki.gronde.eu/getAntworten?frageIds=${frageIdsString}`);
        setAnswers(response.data);
      }
    };

    fetchAnswers();
  }, [questionData, showAnswers]);

  const handleSaveAnswer = async (frageId) => {
    const answerData = {
      user: user,
      fragenId: frageId,
      description: newAnswer[frageId] || '',
      pdfDatei: file_name,
      wann: new Date().toISOString().split('T')[0], // aktuelles Datum im Format YYYY-MM-DD
    };

    try {
      const response = await axios.post('https://api.wiki.gronde.eu/saveAntwort', answerData);
      const savedAnswer = response.data;

      // Optimistisches Update des `ansewrs` Zustands
      setAnswers((prevAnswers) => [...prevAnswers, savedAnswer]);

      setNewAnswer('');
    } catch (error) {
      console.error('Fehler beim Speichern der Antwort:', error);
    }
  };

  const toggleShowAnswers = (frageId) => {
    setShowAnswers((prevState) => ({ ...prevState, [frageId]: !prevState[frageId] }));
    setShowInput(false);
  };

  const handleNewAnswerChange = (index, value) => {
    setNewAnswer((prevState) => ({ ...prevState, [index]: value }));
  };

  const handleShowInputToggle = (index) => {
    setShowInput((prevState) => ({ ...prevState, [index]: !prevState[index] }));
    setShowAnswers(false);
  };

  return (
    <QuestionContainer>
      {questionData && questionData.map((frage, index) => (
        <Container key={index}>
          <QuestionText>
            <FontAwesomeIcon icon={faComment} onClick={() => handleShowInputToggle(index)} style={{ cursor: 'pointer', margin: '10px 10px' }} />
            <FontAwesomeIcon icon={faAngleDown} style={{ cursor: 'pointer', margin: '10px 10px' }} onClick={() => toggleShowAnswers(frage.FrageId)} />
            Fragen: {frage.description}
          </QuestionText>

          {/* Hier Antworten anzeigen, wenn showAnswers true ist */}
          {showAnswers[frage.FrageId] && (
            <ul>
              {ansewrs
                .filter((answer) => answer.fragenId === frage.FrageId)
                .map((answer) => (
                  <AnswerItem key={answer.idAntworten}>{answer.Description}</AnswerItem>
                ))}
            </ul>
          )}

          {showInput[index] && (
            <FormContainer>
              <AnswerTextArea
                placeholder="Geben Sie Ihren Antwort hier ein..."
                value={newAnswer[frage.FrageId] || ''}
                onChange={(e) => handleNewAnswerChange(frage.FrageId, e.target.value)}
              />
              <StyledButton onClick={() => handleSaveAnswer(frage.FrageId)}>Antwort senden</StyledButton>
            </FormContainer>
          )}
        </Container>
      ))}
    </QuestionContainer>
  );
}

export default QuestionAntwortComponent;




 

