import styled from 'styled-components';

export const BodyWrapper = styled.div`
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  margin: 20px;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #4a90e2; /* Consistent border color */
  border-radius: 12px; /* Abgerundete Ecken für einen modernen Look */
  background-color: #f9f9f9; /* Ein hellerer Hintergrund für besseren Kontrast */
  padding: 20px;
  max-width: 100%;
  @media (max-width: 768px) {
    padding: 10px;
    margin: 10px;
  }
`;

export const Container = styled.div`
  margin: auto;
  background-color: #ffffff; /* Weißer Hintergrund für besseren Kontrast */
  border-radius: 12px; /* Abgerundete Ecken */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Leichter Schatten für Tiefe */
  padding: 20px;
  @media (max-width: 768px) {
    padding: 15px; /* Weniger Padding auf kleineren Bildschirmen */
  }
`;

export const Heading = styled.h3`
  color: #4caf50; /* Grüner Farbton für Überschriften */
  margin-bottom: 1rem;
`;

export const SubHeading = styled.h3`
  color: #388e3c; /* Dunklerer Grünton für Unterüberschriften */
  margin-bottom: 1rem;
`;

export const Paragraph = styled.p`
  margin-bottom: 1rem;
`;

export const List = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 1rem;
`;

export const OrderedList = styled.ol`
  margin-left: 1.5rem;
  margin-bottom: 1rem;
`;

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

export const Footer = styled.p`
  font-style: italic;
  margin-top: 2rem;
  color: #757575;
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 2rem;
  width: 150px;
`;
