import styled from "styled-components";

export const Allcont = styled.div`
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  margin: 20px;
  color: #333;
  max-width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const TestimonialCardContainer = styled.div`
   padding: 2rem;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid #4a90e2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  min-height: 300px;
`;
export const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  background-color: #f4f4f4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #4a90e2;
   max-width:100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;
  }
`;

export const MaxWidthContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 90vw;
    padding: 1rem;
  }
`;

export const TestimonialIcon = styled.div`
  color: #4a90e2;
  margin-bottom: 1rem;
`;

export const TestimonialText = styled.div`
  color: #455A64;
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
`;

export const TestimonialImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const TestimonialImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
`;

export const TestimonialName = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

export const TestimonialPosition = styled.p`
  font-size: 0.875rem;
  color: #757575;
  margin: 0;
`;

export const TestimonialsHeader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const TestimonialsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  @media (min-width: 768px) {
    justify-content: space-around;
  }
  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }
`;

export const TestimonialsDescription = styled.p`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #333;
  max-width: 800px;
`;
