import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const colorChange = keyframes`
  0% { color: red; }
  25% { color: darkred; }
  50% { color: red; }
  75% { color: darkred; }
  100% { color: red; }
`;

const jump = keyframes`
  0% { transform: translateY(10px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

export const Container = styled.div`
  margin: 0;
  background-color: #E3F2FD;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  min-width:100vw;
  @media (max-width: 768px) {
    padding: 10px;
    min-height: 80vh;
   
  }
  @media (max-width: 450px) {
    padding: 10px;
    min-height: 45vh;
    width:100%;
   
  }
`;

export const HeaderContainer = styled.div`
  background-image: url(${props => props.bgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${slideDown} 3s ease-out forwards;
  height: 500px;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding:15px 15px;
min-width:100vw;
  @media (max-width: 768px) {
    padding: 5% 2%;
    height: auto;
  }

  @media (max-width: 480px) {
    padding: 20% 5%;
    height: auto;
  }

  @media (min-width: 1024px) {
    padding: 10% 10%;
  }

  h1 {
    font-size: 2.5rem;
    animation: ${colorChange} 10s linear infinite;
    font-weight: 900;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: red;
    white-space: nowrap;

    @media (min-width: 768px) {
      font-size: 3rem;
    }

    @media (min-width: 1024px) {
      font-size: 4rem;
    }

    @media (max-width: 480px) {
      font-size: 2rem;
      white-space: normal; /* Allow wrapping on small screens */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span {
    display: inline-block;
    animation: ${jump} 10s ease infinite;
    font-family: 'Courier New', Courier, monospace;
    color: red;
    font-size: inherit;
  }
`;

