import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [token, setToken] = useState(localStorage.getItem("token") || null);

const login = async (inputs) => {
  try {
    const res = await axios.post("https://api.wiki.gronde.eu/auth/login", inputs, {
      withCredentials: true
    });
    // Ensure the response contains the token before setting it
    if (res.data && res.data.token) {
    
      setCurrentUser(res.data);
      setToken(res.data.token); // Update to res.data.token
      localStorage.setItem("token", res.data.token); // Update to res.data.token
    } else {
      // Log the entire response for debugging purposes
      console.error("Unexpected response format:", res);
      // You can handle this case more gracefully based on your requirements
    }
  } catch (error) {
    // Handle login errors more gracefully
    console.error("Error logging in:", error);
    // You can set an error state here and display it in your UI
  }
};

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  const logout = () => {
    // Clear the token and current user data on logout
    setCurrentUser(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
console.log(currentUser,login,logout,token);
  return (
    <AuthContext.Provider value={{ currentUser, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
