import React,{useState,useContext} from 'react';
import {  useNavigate } from "react-router-dom";
import {RightPanel,LeftPanel,LoginContainer,Card} from './Login.element';
import { AuthContext } from "../../context/authContext";

function Login() {
    const [inputs, setInputs] = useState({
        username: "",
        password: "",
      });
      const [err, setErr] = useState(null);
    
      const navigate = useNavigate();
      const { login } = useContext(AuthContext);

      const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
    
      const handleLogin = async (e) => {
        e.preventDefault();
        try {
          await login(inputs);
       
          navigate(`/home/${inputs.username}`);
        } catch (err) {
          setErr(err.response.data);
        }
      };
    
      return (
        <LoginContainer>
          <Card>
            <LeftPanel>
              <h1>Gronde Schulung </h1>
            
             
             
            </LeftPanel>
            <RightPanel>
              <h1>Login</h1>
              <form>
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  onChange={handleChange}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                />
                {err && <p>{err}</p>}
                <button onClick={handleLogin}>Login</button>
              </form>
            </RightPanel>
          </Card>
        </LoginContainer>
      );
    };

export default Login;
