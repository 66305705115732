import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeather } from '@fortawesome/free-solid-svg-icons';
import clemensImage from '../../images/celemens.png';
import {
  Allcont,
  TestimonialCardContainer,
  TestimonialImageContainer,
  TestimonialImage,
  TestimonialName,
  TestimonialPosition,
  TestimonialText,

  TestimonialsHeader,
  TestimonialsDescription,
  TestimonialsGrid,
  TestimonialIcon,
  MaxWidthContainer
} from './Testimonial.element';

const testimonials = [
  {
    name: 'Lena Gronde',
    position: 'Geschäftsführerin',
    testimonial: 'Lernen ist keine Pflicht, sondern eine Gelegenheit sich selbst und die Welt zu verändern. Somit liegt Bildung und WeiterBildung allein in unserer eigenen Verantwortung.',
    imageUrl: 'https://th.bing.com/th/id/OIP.CD3I6bnLK8Q-1NyVdcvpsAAAAA?w=174&h=180&c=7&r=0&o=5&pid=1.7',
  },
  {
    name: 'Clemens Gronde',
    position: 'Senior Chef',
    testimonial: 'Das Ende der Ausbildung ist der Anfang der Weiterbildung, sonst droht die Einbildung.',
    imageUrl: clemensImage,
  },
];

function Testimonial() {
  return (
    <Allcont>
     
        <TestimonialsHeader>
          <img src='https://gronde.eu/media/c4/dc/74/1632915462/grone-logo.png' alt="Company Logo" style={{ width: '150px', marginBottom: '1rem' }} />
          <TestimonialsDescription>Bildung schafft alles</TestimonialsDescription>
        </TestimonialsHeader>
        <MaxWidthContainer>
          <TestimonialsGrid>
            {testimonials.map((testimonial, index) => (
              <TestimonialCardContainer key={index}>
                <TestimonialIcon>
                  <FontAwesomeIcon icon={faFeather} size="3x" />
                </TestimonialIcon>
                <TestimonialText>"{testimonial.testimonial}"</TestimonialText>
                <TestimonialImageContainer>
                  <TestimonialImage src={testimonial.imageUrl} alt={`Profile picture of ${testimonial.name}`} />
                  <div>
                    <TestimonialName>{testimonial.name}</TestimonialName>
                    <TestimonialPosition>---{testimonial.position}</TestimonialPosition>
                  </div>
                </TestimonialImageContainer>
              </TestimonialCardContainer>
            ))}
          </TestimonialsGrid>
        </MaxWidthContainer>
    
    </Allcont>
  );
}

export default Testimonial;

