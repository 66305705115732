import styled from "styled-components";



export const LoginContainer = styled.div`
  height: 100vh;
  background-color: rgb(193, 190, 255);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Card = styled.div`
  width: 50%;
  max-width: 800px; // Verhindert, dass die Karte zu breit wird
  display: flex;
  flex-direction: row; // Standardmäßig nebeneinander
  flex-wrap: wrap; // Erlaubt Umschlag auf kleinen Bildschirmen
  background-color: white;
  border-radius: 10px;
  min-height: 600px;
  overflow: hidden;
  
  @media (max-width: 768px) {
    width: 90%; // Passt die Breite auf kleineren Bildschirmen an
    flex-direction: column; // Stapelt Panels auf kleinen Bildschirmen
  }
`;


export const LeftPanel = styled.div`
  flex: 1;
  background: linear-gradient(rgba(39, 11, 96, 0.5), rgba(39, 11, 96, 0.5)),
    url("https://th.bing.com/th/id/OLC.L65pwcFVTBJGww480x360?&rs=1&pid=ImgDetMain")
      center;
  background-size: cover;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;

  @media (max-width: 768px) {
    padding: 20px; // Reduziert den Abstand auf kleineren Bildschirmen
    gap: 20px; // Verringert den Abstand zwischen den Elementen
  }

  h1 {
    font-size: 2.5rem; // Reduzierte Schriftgröße für kleinere Bildschirme
    line-height: 2.5rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  button {
    width: auto; // Erlaubt dem Button, sich der Textlänge anzupassen
    padding: 10px;
    @media (max-width: 768px) {
      width: 100%; // Button nimmt die volle Breite ein auf kleinen Bildschirmen
    }
  }
`;

export const RightPanel = styled.div`
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 20px; // Reduziert den Abstand auf kleineren Bildschirmen
    gap: 20px; // Verringert den Abstand zwischen den Elementen
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px; // Passt den Abstand zwischen den Formularelementen an
    
    input, button {
      padding: 15px 10px; // Passt die Polsterung für bessere Bedienbarkeit an
    }
    
    button {
      width: auto; // Erlaubt dem Button, sich der Textlänge anzupassen
      @media (max-width: 768px) {
        width: 100%; // Button nimmt die volle Breite ein auf kleinen Bildschirmen
      }
    }
  }
`;
