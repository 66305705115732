import React,{useContext,useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import QuestionAntwortComponent from '../../component/PdfForm/QuestionAntwortComponent'
import PdfForm from '../../component/PdfForm/PdfForm';
import axios from 'axios';
function FrageSec() {
    const {currentUser }=useContext(AuthContext)
    const {frageId} = useParams();
    const [questionData,setQuestionData]=useState(null);
    console.log(questionData);
    useEffect(() => {
      fetchQuestions(frageId);
    }, [frageId]);
  
   
      
  
    const fetchQuestions = async (pdfuploadname) => {
      try {
        const response = await axios.get(`https://api.wiki.gronde.eu/getFragen?pdfuploadname=${pdfuploadname}`);
        setQuestionData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };
    const handleQuestionSubmit = () => {
      // Rufen Sie fetchFragen auf, um den Zustand zu aktualisieren, oder fügen Sie die neue Frage direkt zu `fragen` hinzu
      fetchQuestions(frageId)
    };

  return (
    <div>
    
      <PdfForm user={currentUser} file_name={frageId}  onSubmitSuccess={handleQuestionSubmit} />
      <QuestionAntwortComponent file_name={frageId} user={currentUser.ID} questionData={questionData}/>
     
    </div>
  )
}

export default FrageSec
