import React, { useState, useContext } from 'react';
import { Input, Button, Form } from './change.element'; // Angenommen, diese Komponenten sind in StyledComponents.js definiert
import { AuthContext } from '../../context/authContext';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const { currentUser, token } = useContext(AuthContext);
  console.log(currentUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state
    setSuccess(false); // Reset success state

    if (newPassword !== confirmPassword) {
      setError('Die neuen Passwörter stimmen nicht überein.');
      return;
    }

    // Hier würden Sie Ihre API-Adresse einfügen, an die das Passwort gesendet wird
    const response = await fetch('https://api.wiki.gronde.eu/auth/changePassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Assuming you need to pass the token in the header
      },
      body: JSON.stringify({
        oldPassword,
        newPassword,
        username: currentUser.Username,
      }),
    });

    if (response.ok) {
      // Erfolgreiche Passwortänderung
      setSuccess(true);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      console.log('Passwort erfolgreich geändert');
    } else {
      // Fehlerbehandlung
      const responseData = await response.json();
      setError(responseData.message || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h1>Passwort ändern</h1>
      <Input
        type="password"
        placeholder="Altes Passwort"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Neues Passwort"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Neues Passwort bestätigen"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Passwort erfolgreich geändert!</p>}
      <Button type="submit">Ändern</Button>
    </Form>
  );
}

export default ChangePassword;
