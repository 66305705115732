import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledDocumentContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const StyledPage = styled(Page)`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    width: 90vw; /* Adjust to fit better on small screens */
  }
`;

const PdfContainer = styled.div`
  overflow-y: auto;
  height: 100vh; /* Make sure the container takes the full height of the viewport */
  @media (max-width: 768px) {
    height: calc(100vh - 50px); /* Adjust for smaller screens */
  }
`;

function PdfViewer({ file_name, pdfFile, setPdfFile }) {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchPdfFile = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.wiki.gronde.eu/uploads/${file_name}`, {
          headers: {
            Authorization: `${token}` // Include the token in the Authorization header
          },
          responseType: 'blob' // Wichtig, um mit Binärdaten wie einem PDF umzugehen
        });
        // Erstellen einer URL für das Blob-Objekt
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPdfFile(fileURL);
      } catch (error) {
        console.error("Fehler beim Laden des PDFs", error);
      } finally {
        setLoading(false);
      }
    };

    if (token && file_name) {
      fetchPdfFile();
    }
  }, [file_name, token, setPdfFile]); // Abhängigkeiten, um den Effect erneut auszuführen, falls sich diese ändern

  useEffect(() => {
    const blockPrintScreen = (e) => {
      if ((e.key === 'PrintScreen')) {
        e.preventDefault();
        alert("Das Erstellen von Screenshots ist nicht erlaubt.");
      }
    };
  
    window.addEventListener('keydown', blockPrintScreen);
  
    return () => {
      window.removeEventListener('keydown', blockPrintScreen);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <PdfContainer onContextMenu={(e) => e.preventDefault()}>
      {loading && <p>Laden...</p>}
      {pdfFile && (
        <StyledDocumentContainer>
          <Document
            key={token}
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from({ length: numPages }, (_, index) => (
              <StyledPage key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </StyledDocumentContainer>
      )}
    </PdfContainer>
  );
}

export default PdfViewer;
