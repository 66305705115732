import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const TextArea = styled.textarea`
  width: 300px;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
`;

function PdfForm({ user, file_name,onSubmitSuccess }) {
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  useEffect(() => {
    setDescription('');
    setSubmitSuccess(false);
    setSubmitError('');
  }, [user, file_name]);

  const handleTextChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    const apiUrl = 'https://api.wiki.gronde.eu/frageein'; // Ersetzen Sie dies durch Ihre tatsächliche API-URL
    const postData = {
      pdfuploadname: file_name,
      userId: user.ID,
      wann: new Date().toISOString().slice(0, 10),
      description: description,
    };

    try {
      const response = await axios.post(apiUrl, postData);
      setIsSubmitting(true);
    
      setDescription(''); // Feld leeren
      setSubmitSuccess(true);
      setSubmitError('');
      onSubmitSuccess(); // Aufruf der Callback-Funktion nach erfolgreichem Submit
    } catch (error) {
      console.error('Fehler beim Senden:', error);
      setSubmitError('Beim Senden der Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <FormContainer>
      <h1>Kommentare oder Fragen zu diesem Thema</h1>
      <TextArea
        value={description}
        onChange={handleTextChange}
        placeholder="Geben Sie Ihren Text hier ein..."
        disabled={isSubmitting}
      />
      <StyledButton onClick={handleSubmit} disabled={isSubmitting}>
        {isSubmitting ? 'Sendet...' : 'Senden'}
      </StyledButton>
      {submitSuccess && <SuccessMessage>Ihre Frage wurde erfolgreich gesendet.</SuccessMessage>}
      {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
    </FormContainer>
  );
}

export default PdfForm;
