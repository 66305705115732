import React from 'react';
import {
  BodyWrapper,
  Container,
  Heading,
  SubHeading,
  Paragraph,
  List,
  OrderedList,
  ListItem,
  Footer,
  Logo
} from './HomeSection.element';

function HomeSection() {
  return (
    <BodyWrapper>
      <Container>
        <Logo src='https://gronde.eu/media/c4/dc/74/1632915462/grone-logo.png' alt="Company Logo" />
        <Heading>Liebe Kollegen,</Heading>
        <Paragraph>
          Wir möchten euch eine großartige Ressource zur Verfügung stellen, um eure berufliche Entwicklung und euer Wachstum zu unterstützen: unsere Lernplattform. Diese Plattform bietet eine Vielzahl von Kursen, Schulungen und Ressourcen, die euch helfen können, eure Fähigkeiten zu erweitern, neue Kenntnisse zu erwerben und eure Karriere voranzutreiben.
        </Paragraph>
        <SubHeading>Nutzungsinformationen:</SubHeading>
        <Paragraph>Für Mitarbeiter mit einer wöchentlichen Arbeitszeit von 30-40 Stunden:</Paragraph>
        <List>
          <ListItem>Euch stehen monatlich 1-2 Stunden zur Verfügung, um die Lernplattform zu nutzen.</ListItem>
          <ListItem>Nutzt diese Zeit, um Kurse zu absolvieren, Schulungen zu besuchen oder euch mit relevanten Materialien vertraut zu machen, die eure berufliche Entwicklung unterstützen.</ListItem>
        </List>
        <Paragraph>Für Mitarbeiter mit einer wöchentlichen Arbeitszeit von 20-30 Stunden:</Paragraph>
        <List>
          <ListItem>Euch steht monatlich 1 Stunde zur Verfügung, um die Lernplattform zu nutzen.</ListItem>
          <ListItem>Verwendet diese Zeit effektiv, um Kurse zu absolvieren oder euch über relevante Themen zu informieren, die eure berufliche Entwicklung fördern können.</ListItem>
        </List>
        <SubHeading>Tipps zur effektiven Nutzung der Lernplattform:</SubHeading>
        <OrderedList>
          <ListItem>Plant eure Lernzeit im Voraus und setzt euch klare Ziele, welche Fähigkeiten oder Kenntnisse ihr erwerben möchtet.</ListItem>
          <ListItem>Nutzt die Suchfunktion, um gezielt nach Kursen oder Materialien zu Themen zu suchen, die für eure berufliche Weiterentwicklung relevant sind.</ListItem>
          <ListItem>Nehmt aktiv an den Kursen teil, macht euch Notizen und setzt das Gelernte in die Praxis um.</ListItem>
          <ListItem>Nutzt auch die Community-Funktionen der Plattform, um euch mit anderen Kollegen auszutauschen und voneinander zu lernen.</ListItem>
        </OrderedList>
        <Paragraph>
          Wir glauben fest daran, dass die kontinuierliche Weiterbildung ein Schlüssel zum Erfolg ist, und hoffen, dass ihr diese Ressource voll ausschöpft. Bei Fragen zur Nutzung der Lernplattform stehen wir euch jederzeit gerne zur Verfügung.
        </Paragraph>
        <Footer>Euer Marc</Footer>
      </Container>
    </BodyWrapper>
  );
}

export default HomeSection;
