import React,{useContext,useState} from 'react';
import { useParams } from 'react-router-dom';
import PdfViewer from '../../component/PdfViewer/PdfViewer';
import { AuthContext } from '../../context/authContext';
import PdfForm from '../../component/PdfForm/PdfForm';
import QuestionAntwortComponent from '../../component/PdfForm/QuestionAntwortComponent';



function PdfAnzeige() {
    const { file_name } = useParams();
    const {currentUser }=useContext(AuthContext)


    const [pdfFile, setPdfFile] = useState(null);


  return (
    <div>
     
      
      <PdfViewer file_name={file_name} pdfFile={pdfFile} setPdfFile={setPdfFile} />
      <PdfForm user={currentUser} file_name={file_name}  />
      <QuestionAntwortComponent file_name={file_name} user={currentUser.ID}/>
      </div>
  )
}

export default PdfAnzeige
