import React from 'react';
import Header from '../../component/Header/Header'
import Testimonial from '../../component/Testimonial/Testimonial';
import HomeSection from '../../component/MainHomeSection/HomeSection';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #4a90e2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
`;

function Home() {
  return (
    <Wrapper>
      
        <Header />
   
      <CenteredContainer>
        <Testimonial />
      </CenteredContainer>
      <CenteredContainer>
        <HomeSection />
      </CenteredContainer>
    </Wrapper>
  );
}

export default Home;

