import React from 'react';
import { Container, HeaderContainer } from './Header.element';
import Background from './../../images/header.png';

function Header() {
  const text = "Lerne  Optik & Akustik with Gronde";
  const letters = text.split("").map((char, index) => (
    <span key={index} style={{ animationDelay: `${0.2 * index}s` }}>{char}</span>
  ));

  return (
    <Container>
      <HeaderContainer bgImage={Background}>
        <h1>{letters}</h1>
      </HeaderContainer>
    </Container>
  );
}

export default Header;




