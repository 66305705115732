import React, { useContext } from 'react';
import TabbarEx from '../../component/Tabbar/TabbarEx';
import { DataContext } from '../../context/DataContext';

function ExternRessource() {
  const { externalData } = useContext(DataContext);

  return (
    <div>
      <TabbarEx data={externalData} />
    </div>
  );
}

export default ExternRessource;
