import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from './authContext';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { currentUser, token } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedStars, setSelectedStars] = useState({});
  const [externalData, setExternalData] = useState([]);
  const [externalStars, setExternalStars] = useState({});
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [selectedExternalCardData, setSelectedExternalCardData] = useState([]);

  useEffect(() => {
    if (token) {
      const userId = currentUser?.ID;

      if (userId) {
        // API-Aufruf, um die Datei-Informationen zu erhalten
        axios.get('https://api.wiki.gronde.eu/getFilesInfo', {
          headers: {
            Authorization: `${token}`
          }
        })
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error('Fehler beim Abrufen der Daten:', error);
          });

        // Zuerst getPdfStarsID aufrufen und danach getPdfInfoSelectedStar
        axios.get(`https://api.wiki.gronde.eu/getPdfStarsID/?userId=${userId}`, {
          headers: {
            Authorization: `${token}`
          }
        })
          .then(response => {
            const updatedSelectedStars = response.data.reduce((acc, curr) => {
              acc[curr.pdfid] = true;
              return acc;
            }, {});
            setSelectedStars(updatedSelectedStars);
            console.log(selectedStars);

            // Danach getPdfInfoSelectedStar aufrufen
            return axios.get(`https://api.wiki.gronde.eu/getPdfStars/?userId=${userId}`, {
              headers: {
                Authorization: `${token}`
              }
            });
          })
          .then(response => {
            setSelectedCardData(response.data);
          })
          .catch(error => {
            console.error('Fehler beim Abrufen der ausgewählten Sterne oder Karten:', error);
          });

        // API-Aufruf, um die externen Ressourcen-Daten zu erhalten
        axios.get('https://api.wiki.gronde.eu/getexternCardInfo', {
          headers: {
            Authorization: `${token}`
          }
        })
          .then((response) => {
            setExternalData(response.data);
          })
          .catch((error) => {
            console.error('Fehler beim Abrufen der externen Ressourcen:', error);
          });

        // API-Aufruf, um die externen Ressourcen-Sterne-IDs zu erhalten
        axios.get(`https://api.wiki.gronde.eu/getCardStarsID/?userId=${userId}`, {
          headers: {
            Authorization: `${token}`
          }
        })
          .then(response => {
            const updatedExternalStars = response.data.reduce((acc, curr) => {
              acc[curr.cardclicked] = true;
              return acc;
            }, {});
            setExternalStars(updatedExternalStars);
          })
          .catch(error => {
            console.error('Fehler beim Abrufen der ausgewählten externen Sterne:', error);
          });

        // API-Aufruf, um die ausgewählten externen Karten zu erhalten
        axios.get(`https://api.wiki.gronde.eu/getCardSelectedStar/?userId=${userId}`, {
          headers: {
            Authorization: `${token}`
          }
        })
          .then(response => {
            setSelectedExternalCardData(response.data);
          })
          .catch(error => {
            console.error('Fehler beim Abrufen der ausgewählten externen Karten:', error);
          });
      }
    }
  }, [currentUser, token]);

  const updateSelectedStars = (pdfId, isSelected) => {
    const data = {
      pdfId,
      userClickedId: currentUser.ID,
      isSelected
    };
    console.log('PdfId', data);

    axios.post('https://api.wiki.gronde.eu/updateSelectedStar', data, {
      headers: {
        Authorization: `${token}`
      }
    })
      .then(response => {
        console.log('Aktualisierung erfolgreich', response.data.message);
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der ausgewählten Sterne:', error);
      });
  };

  const toggleStar = (id) => {
    console.log('Id', id);
    setSelectedStars(prev => {
      const isSelected = !prev[id];
      console.log(isSelected);
      updateSelectedStars(id, isSelected);
      return {
        ...prev,
        [id]: isSelected
      };
    });
  };

  const updateExternalStars = (cardId, isSelected) => {
    const data = {
      cardId,
      userClickedId: currentUser.ID,
      isSelected
    };

    axios.post('https://api.wiki.gronde.eu/updateSelectedCardStar', data, {
      headers: {
        Authorization: `${token}`
      }
    })
      .then(() => {
        setExternalStars(prev => ({
          ...prev,
          [cardId]: isSelected
        }));
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der ausgewählten externen Sterne:', error);
      });
  };

  const toggleExternalStar = (id) => {
    console.log('Id');
    setExternalStars(prev => {
      const isSelected = !prev[id];
      updateExternalStars(id, isSelected);
      return {
        ...prev,
        [id]: isSelected
      };
    });
  };

  return (
    <DataContext.Provider value={{
      data,
      selectedStars,
      toggleStar,
      externalData,
      externalStars,
      toggleExternalStar,
      selectedCardData,
      selectedExternalCardData
    }}>
      {children}
    </DataContext.Provider>
  );
};
