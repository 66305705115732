import React, { useContext } from 'react';
import CardPage from '../../component/Card/CardPage';
import { DataContext } from '../../context/DataContext';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body {
    width: 100%;
    overflow-x: hidden;
  }
`;

export const ContainerHeader = styled.div`
  height: 100px;
  background-color: #93ddc2;
  margin: 0; /* Entfernt die Margin */
  width: 100vw;
`;

function Tutorial() {
  const { data } = useContext(DataContext);

  return (
    <>
      <GlobalStyle />
      <ContainerHeader></ContainerHeader>
      <CardPage data={data} />
    </>
  );
}

export default Tutorial;
