import React, { useEffect, useState, useContext } from 'react';
import { ContainerHeader, NeonButton } from './TabbarExStyle.element';
import { faVideo, faBook, faChalkboardTeacher, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardArea from './CardArea';
import { DataContext } from '../../context/DataContext';

const iconMap = {
  video: faVideo,
  scripts: faBook,
  "online Seminar": faChalkboardTeacher
};

function TabbarEx({ data = [] }) {
  const { toggleExternalStar, externalStars } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState(1);
  const [tabsContent, setTabsContent] = useState({});
console.log(externalStars);

  useEffect(() => {
    if (data && data.length > 0) {
      const transformedData = data.reduce((acc, item) => {
        const tabKey = item.type === "video" ? 1 : item.type === "scripts" ? 2 : 3;
        const icon = iconMap[item.type] || faStar;

        if (!acc[tabKey]) acc[tabKey] = [];
        acc[tabKey].push({ ...item, icon, src: item.link, content: [item.content] });

        return acc;
      }, {});

      setTabsContent(transformedData);
    }
  }, [data]);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };


  
  return (
    <>
      <ContainerHeader>
        <NeonButton onClick={() => handleTabClick(1)}><FontAwesomeIcon icon={faVideo} /> Videos</NeonButton>
        <NeonButton onClick={() => handleTabClick(2)}><FontAwesomeIcon icon={faBook} /> Scripts</NeonButton>
        <NeonButton onClick={() => handleTabClick(3)}><FontAwesomeIcon icon={faChalkboardTeacher} /> Online Seminare</NeonButton>
      </ContainerHeader>
      <CardArea 
        activeTabContent={tabsContent[activeTab] || []}
        externalStars={externalStars}
        toggleExternalStar={toggleExternalStar}
      />
    </>
  );
}

export default TabbarEx;






