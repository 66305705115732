import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const slideIn = keyframes`
  from {
    transform: translateX(-70%);
    opacity: 0;
  }
  to {
    transform: translateX(20);
    opacity: 1;
  }
`;

const borderAnimation = keyframes`
  0% {
    border-color: #544d55;
  }
  50% {
    border-color: #ffffff;
  }
  100% {
    border-color: #544d55;
  }
`;

export const AdditionalCardContainerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  align-items: center; /* Centers items vertically */
  justify-content: space-around; /* Distributes items evenly in the container */
  border-radius: 10px;
`;

export const AdditionalCard = styled.div`
  background-color: #004d66;
  color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.2);
  transition: transform 0.3s ease-in-out;
  will-change: transform; /* Optimizes the animation */
  animation: ${slideIn} 3s ease-in-out; /* Adds the slide-in animation */
  margin-top: 20px;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    width: 90%; /* Adjusts to smaller screens */
  }
`;

export const AdditionalBox = styled.div`
  height: 300px;
  width: 500px;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 30px;
  margin-bottom: 30px; /* Adds additional space at the bottom */

  &:hover {
    transform: translateY(-50px);
 
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
  }
  @media (max-width: 768px) {
    height: 300px;
    width: 250px;
  }
`;

export const AdditionalContent = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px; /* Adds padding to prevent buttons from overlapping the star */
`;

export const AdditionalHeader = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 10px;
  right: 15px;
  font-size: 2rem;
  color: rgba(225, 211, 211, 0.921);

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Smaller font size for mobile devices */
  }
`;

export const AdditionalHeader1 = styled(AdditionalHeader)`
  top: 60px;
  text-align: center;
  width: 100%;
  right: 10px;
  color: white;
  font-weight: 900;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size for mobile devices */
  }
`;

export const ButtonBox1 = styled(Link)`
  position: absolute;
  bottom: 30%; /* Moves the button higher to create more space */
  background: #544d55;
  border-radius: 4px;
  border: 2px solid #544d55;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 3.25em;
  min-width: 15em;
  padding: 0 .5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.3s, color 0.3s;
  animation: ${borderAnimation} 2s infinite;

  &:hover {
    background: #ffffff;
    color: #544d55;
  }

  @media (max-width: 768px) {
    min-width: 10em; /* Reduced minimum width for smaller screens */
    padding: 0.5em; /* Adjusted padding */
  }
`;

export const ButtonBox = styled(Link)`
  position: absolute;
  bottom: 10%; /* Moves the button higher to create more space */
  background: #544d55;
  border-radius: 4px;
  border: 2px solid #544d55;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 3.25em;
  min-width: 15em;
  padding: 0 .5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.3s, color 0.3s;
  animation: ${borderAnimation} 2s infinite;

  &:hover {
    background: #ffffff;
    color: #544d55;
  }

  @media (max-width: 768px) {
    min-width: 10em; /* Reduced minimum width for smaller screens */
    padding: 0.5em; /* Adjusted padding */
  }
`;
