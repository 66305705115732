import React from 'react';
import { CardSection, Card, FlipCard, CardFront, CardFrontTop, CardFrontButton, InsideCard, FlipCardContainer, ContainerCard } from './TabbarExStyle.element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const CardArea = ({ activeTabContent = [], externalStars = {}, toggleExternalStar }) => {
  if (!Array.isArray(activeTabContent)) {
    console.error('activeTabContent is not an array:', activeTabContent);
    return null; // or some fallback UI
  }

  return (
    <ContainerCard>
      {activeTabContent.map((item, index) => {
        if (!item.externcardid) {
          console.error('Item does not have externcardid:', item);
          return null; // or some fallback UI
        }

        return (
          <CardSection key={index}>
            <Card>
              <FlipCard>
                <FlipCardContainer >
                  <CardFront>
                    <CardFrontTop>
                      <FontAwesomeIcon icon={item.icon} className="icon" />
                      <h2>{item.title}</h2>
                      <h3>{item.subtitle}</h3>
                    </CardFrontTop>
                    <FontAwesomeIcon
                    onClick={() => toggleExternalStar(item.externcardid)}
                      icon={faStar}
                      size="2x"
                      className={`star-icon ${externalStars[item.externcardid] ? 'selected' : ''}`}
                      style={{
                        color: externalStars[item.externcardid] ? 'gold' : 'red',
                        cursor: 'pointer'
                      }}
                    />
                    <CardFrontButton href={item.link} target="_blank" rel="noopener noreferrer">
                      View More
                    </CardFrontButton>
                  </CardFront>
                </FlipCardContainer>
              </FlipCard>
              <InsideCard>
                <img src='https://th.bing.com/th/id/OIP.DMoZ-xBoOMMS5h7dZnHsgQHaEC?w=300&h=180&c=7&r=0&o=5&pid=1.7' alt="example" />
              </InsideCard>
            </Card>
          </CardSection>
        );
      })}
    </ContainerCard>
  );
};

export default CardArea;
