import styled  from "styled-components";

export const BodyWrapper =styled.div`
 font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 20px;
  color: black;


`;

