import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import { AdditionalCardContainerWrapper, AdditionalCard, AdditionalBox, AdditionalContent, AdditionalHeader, AdditionalHeader1, ButtonBox1, ButtonBox } from './CardPage.element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Tilt from "react-parallax-tilt";

function CardPage({ data }) {
  const navigate = useNavigate();
  const { selectedStars, toggleStar } = useContext(DataContext);



  const handleStartButtonClick = (file) => {
    navigate(`/home/tutrial/${file}`);
  };

  const handleFrageButtonClick = (file) => {
    navigate(`/home/tutrial/fragen/${file}`);
  };

  return (
    <AdditionalCardContainerWrapper>
      {data.map((cardData) => (
        <Tilt key={cardData.id}>
          <AdditionalCard>
            <AdditionalBox>
              <AdditionalContent>
                <AdditionalHeader>Lektion: {cardData.lektion}</AdditionalHeader>
                <AdditionalHeader1>{cardData.description}</AdditionalHeader1>
                <ButtonBox1 onClick={() => handleStartButtonClick(cardData.file_name)}>Start</ButtonBox1>
                <ButtonBox onClick={() => handleFrageButtonClick(cardData.file_name)}>Fragen</ButtonBox>
                <FontAwesomeIcon
                  icon={faStar}
                  size='2x'
                  onClick={() => toggleStar(cardData.id)}
                  style={{
                    background: 'none',
                    marginBottom:'0px',
                    color: selectedStars[cardData.id] ? 'gold' : 'gray',
                    cursor: 'pointer',
                    zIndex:1000
                  }}
                />
              </AdditionalContent>
            </AdditionalBox>
          </AdditionalCard>
        </Tilt>
      ))}
    </AdditionalCardContainerWrapper>
  );
}

export default CardPage;
