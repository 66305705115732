import styled from 'styled-components';


export const NeonButton = styled.button`

 font-size: 1rem;
  padding: 0.75em 1.5em; 
  border-radius: 30px; 
  background-color: #121212;
  color: #ffffff; 
  border: none; 
  outline: none; 
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, transform 0.2s ease;
  overflow: hidden;
  margin: 10px ;
  box-shadow: inset 0 0 0.5em 0 rgb(50, 210, 110);
  position: relative;
  margin-top:20px;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    background:rgb(5, 220, 240);
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(1em) rotateX(320deg) scale(1.5, 0.35);
    filter: blur(1em);
    opacity: 0.7;
  }

  &:hover,
  &:focus {
    color: rgb(5, 240, 210);
    text-shadow: none;
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
  }
`;
export const ContainerHeader=styled.div`
display:flex;
justify-content:space-around;
align-items:center;
background-color:  #93ddc2;
margin:5px 5px;
width:100vw;

`
export const CardArea=styled.div`
font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
`;

export const CardSection=styled.div`
 /* Karten umbrechen, falls der Bildschirm klein ist */
  gap: 20px; /* Abstand zwischen den Karten, wenn sie umbrechen */
  padding: 20px; /* Etwas Polsterung um den gesamten Card-Bereich */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

 
  `




export const Card = styled.div`
  background-color: #ffffff;
  width: 300px;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease;

  &:hover {
    box-shadow: -0.1rem 1.7rem 6.6rem -3.2rem rgba(0, 0, 0, 0.75);
    width: 30rem;
    transform: scale(1.05);
  }



`;

export const FlipCard = styled.div`
height: 15rem;

position: absolute;
right: 0;
transition: all 1s ease;

width: 15rem;
z-index: 100;

`;
export const FlipCardContainer = styled.div`
 height: 100%;
 

 transform-style: preserve-3d;
 transition: all 1s ease;
 width: 100%;



`;

export const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding:20px 20px;
  backface-visibility: hidden;
  right:0;

  transition: transform 0.6s ease;
  background: linear-gradient( to bottom, #007bff, #004f99);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export  const CardFrontTop=styled.div`
 align-items: center;
  display: flex;
  flex-direction: column;
  height: 12rem;
  justify-content: center;
  padding: .75rem;


`;
export const CardFrontButton=styled.a`
z-index:222;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
 
`;



export const InsideCard=styled.div`
position: relative;

  background-attachment: fixed;
  padding: 1rem;
  position: absolute;
  right: 200px;
  transition: all 1s ease;
  width: 15rem;
  z-index: 1;

`
export const ContainerCard=styled.div`
display:flex;
justify-content:center;
align-items:center;
width:90%;
flex-wrap:wrap;
`;