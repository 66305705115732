import React,{useContext} from 'react';
import { elastic as Menu } from 'react-burger-menu';
import './menu.css'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { AuthContext } from '../../context/authContext';

// Weitere Styled Components hier definieren
const StyledMenu = styled(Menu)`
  // Stil-Anpassungen für das Menü
  .bm-menu {
    width: 100%;
    // Hintergrundfarbe, Breite usw.
    line-height: 2;

  }

  Link, button {
    margin-bottom: 0px; // Reduziert den Abstand unter jedem Element
    padding: 8px 0; // Fügt Polsterung oben und unten hinzu, ohne die Seiten zu beeinflussen
    display: block; // Stellt sicher, dass Links und Buttons die volle Breite einnehmen
  }

  button {
    color: red; // Farbe des Logout-Buttons
  }
`;
function HamburgerMenu(props) {
    const navigate = useNavigate();
    const user  = useContext(AuthContext);

    const handleLogout = async () => {
        try {
          await axios.post('https://api.wiki.gronde.eu/auth/logout');
          localStorage.clear() // Navigieren Sie zur Logged-Out-Seite oder Startseite
          navigate('/logged-out');
      
        } catch (error) {
          console.error('Logout fehlgeschlagen', error);
          // Behandeln Sie den Fehler, eventuell mit einer Fehlermeldung
        }
      };
  return (
    
    <StyledMenu>
        
        <Link to={`/home/${user.currentUser.Username}`}>Home</Link>
        <Link  to={`/profile/${user.currentUser.Username}`}>Profile</Link>
        <Link to ='/home/tutrial'>  Tutorial</Link>
        <Link to='/home/externRessource'>Externe Tutorial</Link>
        <Link to='/home/changePassword'>Kennwort</Link>

        <button onClick={handleLogout}>LogOut</button>
    
    </StyledMenu>
  );
}

export default HamburgerMenu;
