import React, {  useEffect } from 'react';

function LogOut() {
  useEffect(() => {
    localStorage.clear();
    window.location.reload(false); // Force full page reload
  }, []);

  return (
    <div>
      <h1>You are Logged Out!</h1>
    </div>
  );
}

export default LogOut;