import React, { useContext } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom'; // Änderung: Route importieren
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Tutorial from './pages/tutorial/Tutorial';
import ExternRessource from './pages/ExternRess/ExternRessource';
import { AuthContext } from './context/authContext';
import LogOut from './pages/Logout/LogOut';
import Profile from './pages/Profile/Profile';
import HamburgerMenu from './component/Menu/HamburgerMenu';
import PdfAnzeige from './pages/PdfAnzeigenPage/PdfAnzeige';
import FrageSec from './pages/FrageSection/FrageSec';
import ChangePassword from './pages/changePassword/ChangePassword';

function App() {
  const { currentUser } = useContext(AuthContext);

  const Layout = () => (
    <div>
      <HamburgerMenu />
      <Outlet />
    </div>
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: currentUser ? <Layout /> : <Navigate to="/login" />,
      children: [
        { path: '/home/:name', element: <Home /> },
        { path: '/profile/:name', element: <Profile /> },
        { path: '/home/tutrial', element: <Tutorial /> },
        { path: '/home/externRessource', element: <ExternRessource /> },
        { path: '/logged-out', element: <LogOut /> },
        { path: '/home/tutrial/:file_name', element: <PdfAnzeige /> },
        { path: '/home/tutrial/fragen/:frageId', element: <FrageSec /> },
        { path: '/home/changePassword', element: <ChangePassword /> },
      ],
    },
    { path: '/login', element: <Login /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
